<template>
  <view-item title="喷涂盘点单">
    <template #manifest>
      <table-manifest
        :ajax="{ action: 'GET /enospray/stock/taking' }"
        :props="{ start: 'startDate', end: 'endDate' }"
        @row-click="manifest.row.click"
        @expand-click="dialog.visible = true"
      >
        <en-table-column label="单号" prop="serialNo"></en-table-column>
        <en-table-column label="状态" prop="status.message"></en-table-column>

        <template #form="{ data }">
          <en-form-item label="盘点单号">
            <en-input v-model="data.serialNo"></en-input>
          </en-form-item>
          <en-form-item label="制单人">
            <select-maintain
              v-model="data.preparedBy"
              :ajax="{ action: 'GET /enocloud/common/user', params: (params, name) => (params.payload = { name }) }"
              :props="{ label: 'name', value: 'name' }"
              remote
              allow-create
              class="w-full"
            ></select-maintain>
          </en-form-item>
        </template>
      </table-manifest>
    </template>

    <view-item-content :disabled="!form.data.id">
      <en-card v-loading="form.loading" body-class="flex gap-6">
        <span>单号：{{ form.data.serialNo }}</span>
        <en-tag>{{ form.data.status?.message }}</en-tag>
        <span>{{ form.data.preparedBy?.name }}</span>
        <span>{{ formatDate(form.data.auditDatetime, true) }}</span>
        <span>{{ form.data.comment }}</span>
      </en-card>

      <en-card v-loading="form.loading" class="flex-1 overflow-auto" body-class="h-full">
        <flex-box>
          <template #default="{ height }">
            <en-table :data="form.data.stockTakingGoods" :height="height">
              <!-- <en-table-column label="操作">
                <template #default="{ $index }: { $index: number }">
                  <en-button type="primary" link :disabled="form.disabled" @click="form.materialRequisitionGoods.operation.delete.click($index)">
                    删除
                  </en-button>
                </template>
              </en-table-column> -->
              <en-table-column label="编码" prop="goodsSpecification.goods.serialNo"></en-table-column>
              <en-table-column label="名称" prop="goodsSpecification.goods.name"></en-table-column>
              <en-table-column label="规格" prop="goodsSpecification.name"></en-table-column>
              <en-table-column label="账面数量">
                <template #default="{ row }: { row: EnosprayInventoryDefinitions['EnocloudStockTakingGoodsDto'] }">
                  {{ formatNumber(row.originalCount, 1) }}
                </template>
              </en-table-column>
              <en-table-column label="基本单位换算">
                <template #default="{ row }: { row: EnosprayInventoryDefinitions['EnocloudStockTakingGoodsDto'] }">
                  {{ `${row.goodsSpecification?.weight}${row.goodsSpecification?.goods.warehouseUnit}` }}
                </template>
              </en-table-column>
              <en-table-column label="盈亏数量">
                <template #default="{ row }: { row: EnosprayInventoryDefinitions['EnocloudStockTakingGoodsDto'] }">
                  {{ formatNumber(row.adjustingCount, 1) }}
                </template>
              </en-table-column>
              <en-table-column label="实际数量">
                <template #default="{ row }: { row: EnosprayInventoryDefinitions['EnocloudStockTakingGoodsDto'] }">
                  {{ formatNumber(calculator.add(row.originalCount, row.adjustingCount), 1) }}
                </template>
              </en-table-column>
              <en-table-column label="备注" prop="comment"></en-table-column>
            </en-table>
          </template>
        </flex-box>
      </en-card>
    </view-item-content>
  </view-item>
</template>

<script lang="ts">
export default factory({
  config: {
    children: {
      operation: {
        add: {
          async click() {
            this.form.init()
            this.detail.visible = true
          }
        },
        option: {
          async command(option: string) {
            switch (option) {
              case 'detail':
                this.detail.visible = true
                break
            }
          }
        }
      },
      manifest: {
        row: {
          async click(row: EnosprayInventoryDefinitions['EnocloudStockTakingDto']) {
            await this.dirtyCheck('form')
            this.form.init()
            this.form.data.id = row.id
            this.form.get()
          }
        }
      },
      form: {
        ajax: {
          get: {
            action: 'GET /enospray/stock/taking/:stockTakingId',
            data: 'object',
            loading: true,
            init: true,
            dirty: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          }
        }
      },
      detail: {
        visible: false,
        confirm(id: number | undefined) {
          this.form.data.id ??= id
          this.form.get()
        }
      },
      dialog: {
        visible: false
      }
    }
  }
})
</script>
